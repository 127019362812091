<template>
  <div class="flex w-full flex-col">
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <slot />
      </DropdownMenuTrigger>
      <DropdownMenuContent class="w-56" :align="align" :side="side">
        <template v-for="(group, k) in groupedAlertTypes" :key="k">
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <span>{{ group.label }}</span>
            </DropdownMenuSubTrigger>

            <DropdownMenuSubContent>
              <DropdownMenuItem
                v-for="item in group.types"
                :key="item.key"
                @click="simulate(item.key)"
              >
                {{ item.label }}
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        </template>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "../ui/toast";
import {
  simulateEvent,
  groupedAlertTypes,
  type AlertKey,
} from "~/lib/streamlabs-api";

const { toast } = useToast();
const { track: $track } = useTrackingStore();

const simulate = (key: AlertKey) => {
  toast({
    title: "Test Event Issued",
    description: `Your test event was successful!`,
  });

  $track("test_event", {
    test_event_type: key,
  });

  simulateEvent(key);
};

withDefaults(
  defineProps<{
    align?: "center" | "start" | "end";
    side?: "top" | "right" | "bottom" | "left";
  }>(),
  {
    align: "center",
    side: "top",
  },
);

// ...
// https://github.com/stream-labs/desktop/blob/master/app/services/widgets/widgets-data.ts
type Platform =
  | "twitch"
  | "facebook"
  | "trovo"
  | "youtube"
  | "tiktok"
  | "streamlabs";

const platforms: Record<Platform, { label: string }> = {
  twitch: {
    label: "Twitch",
  },
  facebook: {
    label: "Facebook",
  },
  trovo: {
    label: "Trovo",
  },
  youtube: {
    label: "YouTube",
  },
  tiktok: {
    label: "TikTok",
  },
  streamlabs: {
    label: "Streamlabs",
  },
};

const platformEvents: Partial<
  Record<
    Platform,
    { events: { label: string; type: string; platform: string }[] }
  >
> = {
  streamlabs: {
    events: [
      { label: "Tip", type: "donation", platform: "streamlabs" },
      { label: "Merch", type: "merch", platform: "streamlabs" },
    ],
  },
  twitch: {
    events: [
      { label: "Follow", type: "follow", platform: "twitch_account" },
      {
        label: "Subscription",
        type: "subscription",
        platform: "twitch_account",
      },
      { label: "Raid", type: "host", platform: "twitch_account" },
      { label: "Bits", type: "bit", platform: "twitch_account" },
    ],
  },
  facebook: {
    events: [
      { label: "Follow", type: "follow", platform: "facebook_account" },
      { label: "Share", type: "share", platform: "facebook_account" },
      { label: "Support", type: "support", platform: "facebook_account" },
      { label: "Stars", type: "stars", platform: "facebook_account" },
      { label: "Like", type: "like", platform: "facebook_account" },
    ],
  },
  youtube: {
    events: [
      { label: "Subscriber", type: "follow", platform: "youtube_account" },
      {
        label: "Membership",
        type: "subscription",
        platform: "youtube_account",
      },
      { label: "Super Chat", type: "superchat", platform: "youtube_account" },
      // { label: "Cloudbot Redeem" },
      // { label: "SL Ultra Gift" },
    ],
  },
  trovo: {
    events: [
      { label: "Follow", type: "follow", platform: "trovo_account" },
      {
        label: "Subscription",
        type: "subscription",
        platform: "trovo_account",
      },
    ],
  },
};

function formatKey(key: string) {
  const words = key.split("_");
  return words.map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");
}
</script>
